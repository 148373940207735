import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FastDeliveryService } from '../../service/fast-delivery.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {
  timingForm: FormGroup;
  isNewTiming: boolean;
  constructor(
    private fastDeliveryService: FastDeliveryService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TimeslotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Determine if we are editing or adding a new timing
    this.isNewTiming = !data;

    this.timingForm = this.fb.group({
      timing: [this.isNewTiming ? '' : data.timing, [Validators.required]],
      status: [this.isNewTiming ? true : data.status],
    });
  }

  ngOnInit(): void {
  }

  onSave() {
    if (this.timingForm.valid) {
      const farmId = localStorage.getItem('farmId');
      const { timing,status } = this.timingForm.value;
      // console.log({...this.data,["timing"]:timing,['status']:status,["farmId"]:farmId})
      this.fastDeliveryService.updateTimeSlot({...this.data,["timing"]:timing,['status']:status,["farmId"]:farmId}).subscribe(
        (response) => {
          this.dialogRef.close(this.timingForm.value);
        },
        (error) => {
          console.error('Error saving data:', error);
        }
      );
    }
  }

  

  onClose() {
    this.dialogRef.close();
  }

}
