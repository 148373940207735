import { Component, OnInit } from '@angular/core';
import { FastDelivery } from '../../models/fast-delivery';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FastDeliveryService } from '../../service/fast-delivery.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { TimeslotComponent } from '../timeslot/timeslot.component';

@Component({
  selector: 'app-fast-delivery',
  templateUrl: './fast-delivery.component.html',
  styleUrls: ['./fast-delivery.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class FastDeliveryComponent implements OnInit {
  public fastDeliveryEntry: FastDelivery | null = null; 
  fastDeliveryEntryForm: FormGroup;

  constructor(private fastDeliveryService: FastDeliveryService,private dialog: MatDialog) {
    // Initialize the reactive form
    this.fastDeliveryEntryForm = new FormGroup({
      status: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      deliveryRadius: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      durationType: new FormControl('', [Validators.required]),
      farmId: new FormControl('', [Validators.required]),
      timeSlot: new FormControl([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getFastDelivery();
  }

  // Fetch the fast delivery data
  getFastDelivery(): void {
    const farmId = localStorage.getItem('farmId');
    if (!farmId) {
      console.error('Farm ID is not available in local storage.');
      return;
    }

    const details = { farmId };
    this.fastDeliveryService.getFastDelivery(details).subscribe(
      (res: any) => {
        if (res && res.result) {
          this.fastDeliveryEntry = res.result;
          this.populateForm(res.result);
          console.log(res.result)
        }
      },
      (error) => {
        console.error('Error fetching fast delivery details:', error);
      }
    );
  }


  populateForm(data: FastDelivery): void {
    this.fastDeliveryEntryForm.patchValue({
      status: data.status || '',
      amount: data.amount || '',
      deliveryRadius: data.deliveryRadius || '',
      duration: data.duration || '',
      durationType: data.durationType || '',
      farmId: data.farmId || '',
      timeSlot: data.timeSlot || []
    });
  }


  onSubmit(): void {
    if (this.fastDeliveryEntryForm.valid) {
      const formData = this.fastDeliveryEntryForm.value;
      this.fastDeliveryService.updateFastDelivery(formData).subscribe(
        (response) => {
          console.log('Data saved successfully:', response);
        },
        (error) => {
          console.error('Error saving data:', error);
        }
      );
    } else {
      console.error('Form is invalid');
    }
  }

  openDialog(timingData?: any) {
    const dialogRef = this.dialog.open(TimeslotComponent, {
      width: '400px',
      data: timingData
    })

    dialogRef.afterClosed().subscribe(result => {
      // The dialog is closed
      if (result) {
        this.getFastDelivery(); // Call getFastDelivery if needed (based on the result)
      }})
    }

    onDelete(timingData?:any){
      const farmId = localStorage.getItem('farmId');
      this.fastDeliveryService.updateTimeSlot({...timingData,["farmId"]:farmId,["delete"]:true}).subscribe(
        (response) => {
          console.log(response)
          // this.dialogRef.close(this.timingForm.value);
        },
        (error) => {
          console.error('Error saving data:', error);
        }
      );
    }
}
