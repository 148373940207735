<h2 mat-dialog-title>{{ isNewTiming ? 'Add New Timing' : 'Edit Timing' }}</h2>
<mat-dialog-content>
  <form [formGroup]="timingForm">
    <mat-form-field>
      <mat-label>Timing</mat-label>
      <input matInput formControlName="timing" placeholder="Enter timing (e.g., 6am to 10am)">
      <mat-error *ngIf="timingForm.get('timing')?.hasError('required')">Timing is required</mat-error>
    </mat-form-field>

    <mat-checkbox matInput formControlName="status">Active</mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onClose()">Cancel</button>
  <button mat-button [disabled]="!timingForm.valid" (click)="onSave()">Save</button>
</mat-dialog-actions>
