// import { Address } from "./address";

export class Customer {
    _id?: any;
    customerName?: string;
    email?: string;
    mobile?: number;
    accessToken?: number;
    forgotOTP?: string;
    signUpOTP?: string;
    loginOTP: string;
    fcmAndroid?: string;
    profileimage ?: string;
    pincode ?:  string;
    cusType?:string;
    points? :string ;
    relationshipManagerName?:string;
    totalOrders?:number;
    totalAmount?:number;
    lastOrderDate?:string;
    fcmIos?:string;
    signUpOTPExpTime?: number;
    referralCode?: string;
    referenceCode?: string;
    referalTypeStatus?: boolean;
    refferedByCustomerId?: any;
    dob?: Date;
    codType?:boolean;
    deliveryChargeType?:boolean;
    pushNotificationKey?: string;
    addressDetails: Address[];
    isDeleted?: boolean;
    customerType?:string;
    rewardPoint?:number;
    rewardPoints?:number;
    status?: number;
    customerPlan?:string;
    createdOn?: Date;
    createdBy?: string;
    modifiedOn?: Date;
    modifiedBy?: string;
}

export class Address{
  pincode?:string
}
